<template>
  <v-container fluid class="pa-0">
    Just purchased some items? Submit your brands purchased now!

    <v-btn color="primary" rounded block class="mt-4"
      @click="redirect('insightzclub://ShowProfiling/603ca5e686c11b0d5c552ceb')"
    >{{ $t('submit_brands_purchased') }}</v-btn>
  </v-container>
</template>

<script>
export default {
  methods: {
    redirect(path) {
      window.location.href = path
    }
  }
}
</script>